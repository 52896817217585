import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { ApolloError, useMutation } from '@apollo/client';
import qs from 'querystring';

import { GENERAL_VALUATION_MUTATION } from '../../../client/__graphql__/mutations/valuation';
import { PageType } from '../../__types__/generated/globalTypes';
import { ILeadInput, ILeadResponse } from '../../__types__/global';
import { IUseFormField, useForm } from '../hooks/useForm';

import { excludeFields } from '../helpers/excludeFields';
import { createGTMEvent } from '../tracking/gtm';
import { useDigtectiveContext } from '../../__lib__/digtective/context/hooks/useDigtectiveContext';

interface IBaseField {
  type: string;
  name: string;
  label: string;
  error: boolean;
}

interface IFormField extends IBaseField {
  type: string;
  name: string;
  label: string;
  placeholder: string;
  value: string;
  labelAfter: string;
}

interface IFormConsent extends IBaseField {
  type: string;
  name: string;
  label: string;
  placeholder: string;
  checked: boolean;
}

interface IInitConfig {
  pageType: PageType;
  scrollAfterPosted?: boolean;
  showNameFields?: boolean;
  showAddressField?: boolean;
  showEmailField?: boolean;
  fields?: IUseFormField[];
}

interface IValuationContext {
  loading: boolean;
  error: ApolloError | undefined;
  posted: boolean;
  onSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fields: IFormField[];
  consents: IFormConsent[];
  init: (config: IInitConfig, fields?: IUseFormField[]) => void;
}

export const ValuationContext = React.createContext<IValuationContext>({
  loading: false,
  error: undefined,
  posted: false,
  onSubmit: () => {},
  onChange: () => {},
  fields: [],
  consents: [],
  init: () => {}
});

enum Action {
  RESET = 'RESET',
  INIT = 'INIT',
  UPDATE_POSTED = 'UDATE_POSTED',
  UPDATE_LOADING = 'UPDATE_LOADING'
}

type UpdatePostedAction = {
  type: Action.UPDATE_POSTED;
  payload: boolean;
};

type UpdateLoadingAction = {
  type: Action.UPDATE_LOADING;
  payload: boolean;
};

type ResetAction = {
  type: Action.RESET;
};

type ReducerState = {
  pageType: string;
  posted: boolean;
  loading: boolean;
  scrollAfterPosted: boolean;
  showNameFields: boolean;
  showAddressField: boolean;
  showEmailField: boolean;
  fieldsConfig: IUseFormField[];
};

type InitAction = {
  type: Action.INIT;
  payload: Partial<Omit<ReducerState, 'posted' | 'loading'>>;
};

type ReducerActions =
  | UpdatePostedAction
  | UpdateLoadingAction
  | InitAction
  | ResetAction;

const initialState: ReducerState = {
  pageType: '',
  posted: false,
  loading: false,
  scrollAfterPosted: false,
  showNameFields: false,
  showAddressField: true,
  showEmailField: true,
  fieldsConfig: []
};

const reducer = (state: ReducerState, action: ReducerActions) => {
  switch (action.type) {
    case Action.RESET:
      return initialState;
    case Action.INIT:
      let obj: Partial<ReducerState> = { ...action.payload };
      if (typeof action.payload.scrollAfterPosted === 'boolean') {
        obj.scrollAfterPosted = action.payload.scrollAfterPosted;
      }
      return {
        ...state,
        ...obj,
        pageType: action.payload.pageType as PageType
      };
    case Action.UPDATE_LOADING:
      return { ...state, loading: action.payload };
    case Action.UPDATE_POSTED:
      return { ...state, posted: action.payload };
    default:
      throw new Error(`unknown action`);
  }
};

export const ValuationContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const params = useParams<{
    urltag?: string;
    broker?: string;
    path?: string;
  }>();
  const { submitWithDigger } = useDigtectiveContext();

  const [queryState] = React.useState(qs.parse(location.search.slice(1)));
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [lead, { error }] = useMutation<ILeadResponse, ILeadInput>(
    GENERAL_VALUATION_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (state.scrollAfterPosted) {
          window.scroll(0, 0);
        }
        if (lead.valuation?.success) {
          dispatch({ type: Action.UPDATE_LOADING, payload: false });
          dispatch({ type: Action.UPDATE_POSTED, payload: true });
          createGTMEvent({
            event: 'pmFormSubmission',
            gaEvent: 'Verdivurdering NY',
            gaCategory: 'Form',
            gaAction: 'Submit'
          });
        }
      }
    }
  );

  const init = (config: IInitConfig, fields?: IUseFormField[]) => {
    const fieldsConfig: IUseFormField[] =
      Array.isArray(fields) && fields?.length
        ? fields
        : [
            ...(config.showNameFields
              ? [
                  {
                    type: 'text',
                    name: 'firstName',
                    placeholder: 'Ola',
                    value: '',
                    label: 'Fornavn *',
                    required: true
                  },
                  {
                    type: 'text',
                    name: 'lastName',
                    placeholder: 'Nordmann',
                    value: '',
                    label: 'Etternavn *',
                    required: true
                  }
                ]
              : []),
            ...(config.showAddressField !== false
              ? [
                  {
                    type: 'text',
                    name: 'address',
                    placeholder: 'Eks. Adresseveien 23',
                    value: '',
                    label: 'Adresse'
                  }
                ]
              : []),
            {
              type: 'text',
              name: 'zip',
              placeholder: 'Eks. 1234',
              value: '',
              label: 'Postnummer *',
              required: true
            },
            {
              type: 'tel',
              name: 'phone',
              placeholder: 'Eks. 00000000',
              value: '',
              label: 'Telefon *',
              labelAfter: '(Brukes til å kontakte deg)',
              required: true
            },
            ...(config.showEmailField !== false
              ? [
                  {
                    type: 'email',
                    name: 'email',
                    placeholder: 'Eks. ole@mail.no',
                    value: '',
                    label: 'E-post *',
                    labelAfter: '(Brukes til å kontakte deg)',
                    required: true
                  }
                ]
              : []),
            {
              type: 'checkbox',
              name: 'kjop',
              checked: false,
              label: 'I forbindelse med hjelp til å finne og kjøpe eiendommer'
            },
            ...(queryState?.sguid
              ? [
                  {
                    type: 'checkbox',
                    name: 'contactNearby',
                    checked: true,
                    label: 'Ønsker kontakt med megler på dette oppdraget'
                  },
                  {
                    type: 'checkbox',
                    name: 'contactNearby',
                    checked: false,
                    label: 'Ønsker kontakt med PrivatMegleren i mitt nærmiljø.'
                  }
                ]
              : [])
          ];
    dispatch({ type: Action.INIT, payload: { ...config, fieldsConfig } });
  };

  const getDiggerPayload = (args: { zip: string }) => {
    switch (state.pageType) {
      case PageType.NORDEASELGEKJOPE:
      case PageType.GENERELL:
        return { zip: args.zip };
      case PageType.KONTOR:
        return { zip: args.zip, office: params.urltag };
      case PageType.MEGLER:
        return { zip: args.zip, broker: params.broker };

      default:
        throw new Error('current pageType is not implemented');
    }
  };

  const createInputObj = (args: any) => {
    let inputObj: ILeadInput['input'] = {
      zip: args.zip,
      phone: args.phone,
      consents: {
        kjop: args.kjop,
        finansiering: args.finansiering
      },
      diggerId: args?.diggerId,
      referer: `${location.pathname}${location.search}`,
      pageType: state?.pageType as any,
      leadType: 'VERDIVURDERING'
    };

    if (state.showAddressField) {
      inputObj.address = args.address;
    }
    if (state.showEmailField) {
      inputObj.email = args.email;
    }
    if (state.showNameFields) {
      inputObj.firstName = args.firstName;
      inputObj.lastName = args.lastName;
    }

    switch (state.pageType) {
      case PageType.KONTOR:
        inputObj = {
          ...inputObj,
          identifier:
            params.urltag === 'vikoyr' ? 'vikoyrbrandt' : params.urltag
        };
        //console.log('here', inputObj, 'params', params.urltag);
        break;
      case PageType.MEGLER:
        /* Workaround - change pagetype if consent is true */
        if (queryState?.sguid && !args.contactNearby) {
          inputObj = {
            ...inputObj,
            pageType: PageType.OBJEKT,
            identifier: queryState.sguid as string
          };
        } else {
          inputObj = {
            ...inputObj,
            identifier: params.broker || params.path
          };
        }
        break;
      default:
        break;
    }
    return { input: inputObj };
  };

  const {
    fields,
    handleSubmit: onSubmit,
    handleChange: onChange
  } = useForm({
    fieldConfigChanged: state.fieldsConfig.length > 0,
    fields: state.fieldsConfig,
    submit: (fields) => {
      if (!state.pageType) {
        throw new Error('pageType not defined for query');
      }
      dispatch({ type: Action.UPDATE_LOADING, payload: true });
      submitWithDigger(
        {
          zip: fields?.zip
        },
        (args) => {
          lead({
            variables: createInputObj({ ...fields, diggerId: args?.diggerId })
          });
        }
      );
    }
  });

  return (
    <ValuationContext.Provider
      value={{
        init,
        onSubmit,
        onChange,
        posted: state.posted,
        error: error,
        loading: state.loading,
        fields: excludeFields(fields),
        consents: excludeFields(fields, 'consent')
      }}
    >
      {children}
    </ValuationContext.Provider>
  );
};
